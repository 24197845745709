import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { createUseStyles } from "react-jss";
// import ReactPlayer from "react-player";

const talkingVideo = require('../assets/videos/talking.mp4')
const silenceVideo = require('../assets/videos/silent.mp4')
const video_poster = require('../assets/videos/video_poster.webp')

const VideoView = (props, ref) => {
    const style = useStyle()
    const videoPlayer = useRef()
    const [isVideoStart, setIsVideoStart] = useState(false)

    useEffect(() => {

    }, [])

    useImperativeHandle(ref, () => ({
        playVideo: () => {
            setIsVideoStart(true)
            videoPlayer.current.play()
        },
        stopVideo: () => {
            setIsVideoStart(false)
            videoPlayer.current.play()
        },
    }));

    return (
        <div style={{backgroundImage: video_poster }} id="videoBox">
            {/* <ReactPlayer
                ref={videoPlayer}
                url={isVideoStart ? talkingVideo : silenceVideo}
                playing={true}
                loop={true}
                width="100%"
                height="100%"
                controls={false}
                config={{
                    file: {
                        attributes: {
                            poster: video_poster
                        }
                    }
                }}
            /> */}
            <video ref={videoPlayer} loop muted className={style.video} id="video" src={isVideoStart ? talkingVideo : silenceVideo} poster={video_poster}></video>
            
        </div>
    );
}

const useStyle = createUseStyles({
    video:{
        height:'100%'
    }
})

export default forwardRef(VideoView);
