import React, { useImperativeHandle, useEffect, forwardRef, useRef, useState } from "react";
import { createUseStyles } from "react-jss";

const PolicyInnerDetailsModal = (props, ref) => {


    const [isModalShow, setIsModalShow] = useState(false)
    const [title, setTitle] = useState(false)
    const [description, setDescription] = useState(false)

    const style = useStyle({ isModalShow })


    useImperativeHandle(ref, () => ({
        show: (title, description) => {
            setTitle(title)
            setDescription(description)
            setIsModalShow(true)
        },
        hide: () => {
            setIsModalShow(false)
        },
    }));


    const onPressButton = () => {
        setIsModalShow(false)
    }


    return (
        <div className={style.mainContainer}  onClick={() => { setIsModalShow(false) }}>
            <div className={style.modalContainer}>
                <div className={style.modalContentsMainContainer} >
                    <h3 className={style.smallHeaderLabel}>{title}</h3>
                    <div className={style.contentsContainer}>
                        <p className={style.desc} style={{ marginTop: '2%', lineHeight:1.5 }}>
                            {description}
                        </p>
                    </div>
                    {/* <div className={style.crossIconContainer}>
                        <img className={style.crossIcon} src={ic_cross} />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

const useStyle = createUseStyles({

    mainContainer: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#00000050',
        position: 'absolute',
        left: 0,
        zIndex: 12000,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "top 0.10s fade-in",
        top: (props) => props.isModalShow ? 0 : '-100%',

    },
    modalContainer: {
        width: '40%',
        height: '40%',
        backgroundColor: 'white',
        alignSelf: 'center',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    modalContentsMainContainer: {
        width: '80%',
        height: '85%',
        backgroundColor: 'white',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    headerLabel: {
        width: '100%',
        color: '#429EFA',
        fontSize: '4vh',
        margin: 0
    },
    smallHeaderLabel: {
        width: '100%',
        color: '#2A2A2A',
        fontWeight: 'normal',
        fontSize: '2vh',
        margin: 0,
        marginTop: '1%'

    },
    desc: {
        width: '100%',
        maxWidth: '100%',
        // textAlign: 'center',
        color: 'black',
        margin: 0,
        fontWeight: 'normal',
        fontSize: '1.5vh',
        flexShrink: 1,


    },
    buttonContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    button: {
        margin: '2%',
        color: 'white',
        backgroundColor: '#429EFA',
        borderColor: 'transparent',
        padding: '1%',
        borderRadius: 100,
        paddingLeft: '8%',
        paddingRight: '8%',

    },
    contentsContainer: {
        flex: 1,
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        alignSelf: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        // display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        whiteSpace: 'pre-wrap',
        margin: 0,
        marginTop: '1%',

    },
   
})

export default forwardRef(PolicyInnerDetailsModal);
