import React, { useImperativeHandle, useEffect, forwardRef, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import Lottie from 'react-lottie';
import { Modal } from 'react-bootstrap';
import error_animation from '../assets/animation/error_animation.json'


const SessionExpireModal = (props, ref) => {

    let { onPressNewSession } = props

    const [isSessionExpired, setIsSessionExpired] = useState(false)

    const style = useStyle({ isSessionExpired })


    useImperativeHandle(ref, () => ({
        show: () => {
            setIsSessionExpired(true)
        },
        hide: () => {
            setIsSessionExpired(false)
        },
    }));

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: error_animation,
        rendererSettings: {
            //   preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <Modal show={isSessionExpired} animation={true} size="md" centered>
            <Modal.Body className={style.ModalBody} id="chatExpiryPopupView">
                <Lottie options={defaultOptions}
                    height={'8vh'}
                    width={'8vh'} 
                    isPaused={false}
                    />
                <h3 className={style.sessionModalHeader}>Session Expired</h3>
                <h6 className={style.sessionModalDesc}>It seems like you've been away for too long.</h6>
                <div style={{ textAlign: 'center' }}>
                    <button className={style.sessionModalButton} onClick={() => onPressNewSession()}>Restart Session</button>
                </div>
            </Modal.Body>
        </Modal>

        // <div className={style.chatExpiryScreen}>
        //     <div className={style.chatExpiryPopupView} id="chatExpiryPopupView" >
        //         <Lottie options={defaultOptions}
        //             height={'8vh'}
        //             width={'8vh'} />
        //         <h3 className={style.sessionModalHeader}>Session Expired</h3>
        //         <h6 className={style.sessionModalDesc}>It seems like you've been away for too long.</h6>
        //         <button className={style.sessionModalButton} onClick={() => onPressNewSession()}>Restart Session</button>
        //     </div>
        // </div>
    );
}

const useStyle = createUseStyles({

    chatExpiryScreen: {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 0,
        zIndex: 12000,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "top 0.5s ease-in-out",
        top: (props) => props.isSessionExpired ? 0 : '-100%',

    },
    chatExpiryPopupView: {
        width: '30%',
        height: '40%',
        backgroundColor: 'white',
        alignSelf: 'center',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    sessionModalHeader: {
        textAlign: 'center',
        color: '#429EFA'
    },
    sessionModalDesc: {
        textAlign: 'center',
        color: '#429EFA'
    },
    sessionModalButton: {
        // width: '12vh',
        // height: '4vh',
        color: 'white',
        alignSelf: 'center',
        backgroundColor: '#429EFA',
        borderColor: 'transparent',
        padding: '2%',
        borderRadius: 100,
        marginTop: '5%'

    },
    error_gif: {
        width: '5vh',
        height: '5vh',
        objectFit: 'contain',
        objectPosition: 'center'
    }
})

export default forwardRef(SessionExpireModal);
