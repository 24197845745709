import ic_brain from "../assets/icons/ic_brain.png";

import { Button, Input, Image } from "antd";
import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  memo,
} from "react";
import { createUseStyles } from "react-jss";

const CompanyLogo = ({ }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const style = useStyle({ isMouseOver });

  useEffect(() => { }, []);

  return (
    <div className={style.leftContainer}>
        
        <div className={style.overViewContainer} onClick={() => {}}>
          <div className={style.overViewInnerContainer}>
            <text
              style={{ fontSize: "1.6vh", color: "#429EFA", fontWeight: "bold" }}
            >
              Virtual Psychologist
            </text>
            <text
              style={{ fontSize: "1.6vh", color: "white", fontWeight: "bold" }}
            >
              Powered by{" "}
              <a
                href={"https://dls-global.com/"}
                target={"_blank"}
                style={{
                  color: "#429EFA",
                  fontSize: "1.5vh",
                  paddingLeft: "0.5vw",
                  margin: 0,
                }}
              >
                {" "}
                Digital Landscape
              </a>
            </text>
          </div>
        </div>
        <div
          className={style.brainButtonContainer}
          onMouseOver={() => {
            setIsMouseOver(true);
          }}
          onMouseOut={() => {
            setIsMouseOver(false);
          }}
        >
          <img src={ic_brain} className={style.brainButtonImage} />
        </div>

      {/* <div className={style.companyNameContainer}>
        <h6 style={{ color: "white", fontSize: "1.5vh", margin: 0 }}>
          Powered by{" "}
        </h6>
        <a
          href={"https://dls-global.com/"}
          target={"_blank"}
          style={{
            color: "#429EFA",
            fontSize: "1.5vh",
            paddingLeft: "0.5vw",
            margin: 0,
          }}
        >
          {" "}
          Digital Landscape
        </a>
      </div> */}
    </div>
  );
};

const useStyle = createUseStyles({
  leftContainer: {
    margin: "10px 0",
    display: "flex",
    alignItems: "center",
    background: "#FFFFFF30",
    borderRadius: "100px",
  },
  brainButtonContainer: {
    display: "flex",
    cursor: "pointer",
  },
  brainButtonImage: {
    width: "7vh",
    height: "7vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  overViewContainer: {
    // width: "15%",
    // height: "6vh",
    padding: "5px 0 5px 20px",
    // background: "#FFFFFF30",
    // borderRadius: 100,
    display: (props) => (props.isMouseOver ? "flex" : "none"),
    // position: "absolute",
    // justifyContent: "flex-start",
    // alignItems: "center",
    transition: "right 0.5s ease-in-out",
    // flexDirection: "row",
  },
  overViewInnerContainer: {
    width: "17vh",
    height: "6.5vh",
    background: "transparent",
    justifyContent: "center",
    display: "flex",
    // alignItems: "center",
    transition: "right 0.5s ease-in-out",
    flexDirection: "column",
    borderRadius: 100,
  },
  companyNameContainer: {
    display: "flex",
    marginBottom: "2vh",
  },
});

export default memo(CompanyLogo);
