// import { useSpeechRecognition } from 'react-speech-kit';
// import ic_send from '../assets/icons/ic_send.png'
import ic_send_disable from '../assets/icons/ic_send_disable.svg'
import ic_send from '../assets/icons/ic_send.svg'
import ic_mic from '../assets/icons/ic_mic.png'

import { Button, Input, Image } from "antd";
import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import { createUseStyles } from "react-jss";
import { Audio, Bars } from 'react-loader-spinner'
import useSpeechToText from 'react-hook-speech-to-text';

const InputBox = ({ onPressSendButton, isInputEnable, onStopVideoAndVoice }) => {


    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false
    });



    // const { listen, listening, stop } = useSpeechRecognition({
    //     onResult: (result) => {
    //         // setText(result);
    //         // console.log(result)
    //     },
    // });
    const style = useStyle({ isRecording, isInputEnable })

    const [text, setText] = useState('')
    const inputRef = useRef()

    useEffect(() => {
        results.map((result, index) => {
            if (index === results.length - 1) {
                onPressSendButton(result?.transcript)
                // console.log(result?.transcript)
            }
        })
    }, [results])



    useEffect(() => {
        if (isInputEnable)
            inputRef.current.focus()
    }, [isInputEnable])



    const sendInputMessage = (msg) => {
        if (msg !== '') {
            setText('')
            onPressSendButton(msg)
        }
    }

    const onChangeText = (e) => {
        setText(e.target.value)
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendInputMessage(e.target.value)
        }
    }

    const onToggleMic = () => {
        // if(listening){
        //     stop()
        // }else{
        //     listen(false,'en-EN')
        // }
        onStopVideoAndVoice()
        if (isRecording) {
            stopSpeechToText()
        } else {
            // startSpeechToText(false, 'en-EN')
            startSpeechToText()
        }


    }

    return (

        <div className={style.inputBox} >
            {/* <div className={style.micContainer}>

                <div className={style.micButtonCotainer}>
                    <img src={ic_mic} className={style.micButton} onClick={() => { onToggleMic() }} />
                </div> */}

            {/* {isRecording &&
                    <>
                        <Bars
                            heigth="10%"
                            width="50%"
                            color='#429EFA'
                            ariaLabel='loading'
                        />
                        <h6>recording...</h6>
                    </>
                } */}
            {/* </div> */}

            <Input
                ref={inputRef}
                disabled={!isInputEnable}
                value={text}
                className={style.inputFiled}
                placeholder="Type and talk"
                onChange={onChangeText}
                onKeyDown={handleKeyDown} />
            <img src={isInputEnable ? ic_send : ic_send_disable} className={style.sendButton} onClick={() => {
                sendInputMessage(text)
            }} />

        </div>
    );
}


const useStyle = createUseStyles({
    inputBox: {
        height: "6%",
        marginTop: '0.8vh',
        // backgroundColor: 'white',
        backgroundColor: (props) => props.isInputEnable ? 'white' : 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 100,
        pointerEvents: (props) => props.isInputEnable ? 'auto' : 'none',
        webkitBoxShadow: "2px 4px 4px #9E9E9E",
        mozBoxShadow: "2px 4px 4px #9E9E9E",
        boxShadow: "2px 4px 4px #9E9E9E",

    },
    inputFiled: {
        width: "100%",
        // fontSize: '2vw',
        border: 'none',
        borderColor: 'transparent',
        borderRadius: '100px',
        paddingLeft: '17px',
        outline: 'none',
        '&:hover': {
        },
        '&:active': {
            borderColor: 'white',
            borderWidth: 0
        },
        '&::-webkit-input-placeholder': { /* Edge */
            color: '#A3A3A3'
        },
        '&::-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: '#A3A3A3'
        },
        '&::placeholder': {
            color: '#A3A3A3'
        }
    },
    sendButton: {
        width: '4vh',
        height: '4vh',
        margin: '0.5vh',
        padding: '0.5vh',
        cursor: 'pointer',
    },
    micContainer: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent'
    },
    micButtonCotainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        padding: '1%',
        // backgroundColor: (props) => props.isRecording ? 'pink' : 'trasnparent',
        borderRadius: 200,
        marginRight: '2%',

    },
    micButton: {
        width: '2vh',
        height: '4vh',
        marginRight: '2vh',
        marginLeft: '2vh',
        alignSelf: 'center',
        border: 0,
        background: 'transparent',
        objectFit: 'contain',
        objectPosition: 'center',

    }

})

export default memo(InputBox);
