import ic_plus from '../assets/icons/ic_plus.png'
import ic_checked from '../assets/icons/ic_checked.png'
import ic_unchecked from '../assets/icons/ic_unchecked.png'
import "../assets/styles/style.css"
import React, { useImperativeHandle, useEffect, forwardRef, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { PolicyInnerDetailsModal } from '../components'
import { Modal } from 'react-bootstrap';
const title1 = 'WHO IS RUNNING THIS SYSTEM?'
const title2 = 'WHAT INFORMATION ARE WE COLLECTING?'
const title3 = 'WHY ARE WE COLLECTING THIS INFORMATION?'

const desc1 = 'Soul Machines Limited is a New Zealand company whose address is L1,\n106 Customs Street West, Auckland, 1010, New Zealand.\n\nSoul Machines have built a “Digital Brain”\nand “Embodied Cognitive User Experience” which we call a “Digital Person”.'
const desc2 = 'For the Digital Person to be able to interact with you in an authentic and human way,\n\n their Digital Brain™ needs to collect and process information about your expressions.\nAs soon as it is collected, this information is anonymized.\n\n We do not keep this information, provide it to third parties, or share it. It is purely used while you are interacting with the Digital Person to generate the best experience possible.'
const desc3 = 'We want your experience with the Digital Person to be as fun, natural and engaging as possible.\n You can help us do this by allowing the Digital Person to study and respond to your expressions in order to interpret your emotional response so that they can provide an appropriate answer. \nIt’s just like a conversation with a real person where they are not just interpreting what you say,\n but how you say it.In the process, you are helping advance technology and experiencing the future of human-to-machine interaction.All conversation data collected is anonymized and complies with current privacy practices and regulations.\n\n We take your privacy very seriously.'


const PolicyAgreementModal = (props, ref) => {

    let { onPressLetsGo } = props
    const [isChecked, setIsChecked] = useState(false);

    const [isModalShow, setIsModalShow] = useState(false)
    const policyInnerDetailsModalRef = useRef()

    const style = useStyle({ isModalShow, isChecked })


    useImperativeHandle(ref, () => ({
        show: () => {
            setIsModalShow(true)
        },
        hide: () => {
            setIsModalShow(false)
        },
    }));


    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };


    const onPressButton = () => {
        setIsModalShow(false)
        onPressLetsGo()
    }


    return (
        <Modal show={isModalShow} animation={false} size="lg">
        <Modal.Body className={style.ModalBody} id="modalContainer">
             <h3 className={style.headerLabel} id="headerLabel">First things first</h3>
             <h3 className={style.smallHeaderLabel} id="smallHeaderLabel">FOR ME TO WORK BEST, I’D LIKE TO BE ABLE TO HEAR YOUR VOICE.</h3>
                     <div className={style.contentsContainer}>
                         <p className={style.desc} style={{ marginTop: '2%', lineHeight: 1.5 }}>
                             This will be just like a video call where we can talk face to face. If that sounds ok,<br />
                             please turn on access to your microphone and camera when we request it.
                             <br /><br />
                             The speed of your internet connection can have a big impact on the picture quality in the call, <br />
                             if you do experience poor quality we’d love it if you could run a speed test and <br />
                             let us know the results in your feedback when you end the session.
                             <br /><br />
                             I can find it hard to hear you when you’re in a noisy room, or when there are other conversations going on around you.<br />
                             Please call me from a quiet place and let’s keep this one-on-one for now.
                             <br /><br />
                             Ask me a question and I’ll do my best to answer it, if I don’t have an answer for you,<br />
                             I’ll do my best to provide you with a reasonable next step.
                         </p>
                         <div className={style.clickableOptionContainer} onClick={() => { policyInnerDetailsModalRef.current.show(title1, desc1) }}>
                             <h6 className={style.questionLabel}>WHO IS RUNNING THIS SYSTEM?</h6>
                             <img className={style.questionIcon} src={ic_plus} />
                         </div>
                         <div className={style.clickableOptionContainer} onClick={() => { policyInnerDetailsModalRef.current.show(title2, desc2) }}>
                             <h6 className={style.questionLabel}>WHAT INFORMATION ARE WE COLLECTING?</h6>
                             <img className={style.questionIcon} src={ic_plus} />
                         </div>
                         <div className={style.clickableOptionContainer} onClick={() => { policyInnerDetailsModalRef.current.show(title3, desc3) }}>
                             <h6 className={style.questionLabel}>WHY ARE WE COLLECTING THIS INFORMATION?</h6>
                             <img className={style.questionIcon} src={ic_plus} />
                         </div>
                         <p className={style.desc} id="desc" style={{ marginTop: '2%', lineHeight: 1.5 }}>
                             If you want to find out more information and how we collect<br /> and use your information please see our Privacy Policy here.
                             <br /><br />
                             If you’re happy to proceed on this basis,<br /> please confirm your acceptance of our Privacy Policy:
                         </p>
                     </div>
                     <div className={style.checkedButtonContainer} id="checkedButtonContainer" onClick={() => { handleOnChange() }} >
                         <img src={isChecked ? ic_checked : ic_unchecked} className={style.checkbox} id="checkbox" />
                         <p className={style.desc} style={{ marginLeft: '5%', color: '#429EFA', fontSize: '1.7vh', lineHeight: 1.5 }} >
                             ACCEPT PRIVACY TERMS AND END USER AGREEMENT - EULA
                             ALLOW ACCESS TO CAMERA, MICROPHONE AND USAGE DATA.
                         </p>
                     </div>
                     <div className={style.buttonContainer} >
                         <button className={style.button} id="modalbtn" onClick={() => { onPressButton() }}>LET'S GO!</button>
                     </div>
                     {/* <PolicyInnerDetailsModal ref={policyInnerDetailsModalRef} /> */}
        </Modal.Body>
      </Modal>
    
        // <div className={style.mainContainer} id="mainContainer">
        //     <div className={style.modalContainer}  id="modalContainer">
        //         <div className={style.modalContentsMainContainer} >

        //             <h3 className={style.headerLabel} id="headerLabel">First things first</h3>
        //             <h3 className={style.smallHeaderLabel}>FOR ME TO WORK BEST, I’D LIKE TO BE ABLE TO HEAR YOUR VOICE.</h3>
        //             <div className={style.contentsContainer}>
        //                 <p className={style.desc} style={{ marginTop: '2%', lineHeight: 1.5 }}>
        //                     This will be just like a video call where we can talk face to face. If that sounds ok,<br />
        //                     please turn on access to your microphone and camera when we request it.
        //                     <br /><br />
        //                     The speed of your internet connection can have a big impact on the picture quality in the call, <br />
        //                     if you do experience poor quality we’d love it if you could run a speed test and <br />
        //                     let us know the results in your feedback when you end the session.
        //                     <br /><br />
        //                     I can find it hard to hear you when you’re in a noisy room, or when there are other conversations going on around you.<br />
        //                     Please call me from a quiet place and let’s keep this one-on-one for now.
        //                     <br /><br />
        //                     Ask me a question and I’ll do my best to answer it, if I don’t have an answer for you,<br />
        //                     I’ll do my best to provide you with a reasonable next step.
        //                 </p>
        //                 <div className={style.clickableOptionContainer} onClick={() => { policyInnerDetailsModalRef.current.show(title1, desc1) }}>
        //                     <h6 className={style.questionLabel}>WHO IS RUNNING THIS SYSTEM?</h6>
        //                     <img className={style.questionIcon} src={ic_plus} />
        //                 </div>
        //                 <div className={style.clickableOptionContainer} onClick={() => { policyInnerDetailsModalRef.current.show(title2, desc2) }}>
        //                     <h6 className={style.questionLabel}>WHAT INFORMATION ARE WE COLLECTING?</h6>
        //                     <img className={style.questionIcon} src={ic_plus} />
        //                 </div>
        //                 <div className={style.clickableOptionContainer} onClick={() => { policyInnerDetailsModalRef.current.show(title3, desc3) }}>
        //                     <h6 className={style.questionLabel}>WHY ARE WE COLLECTING THIS INFORMATION?</h6>
        //                     <img className={style.questionIcon} src={ic_plus} />
        //                 </div>

        //                 <p className={style.desc} id="desc" style={{ marginTop: '2%', lineHeight: 1.5 }}>
        //                     If you want to find out more information and how we collect<br /> and use your information please see our Privacy Policy here.
        //                     <br /><br />
        //                     If you’re happy to proceed on this basis,<br /> please confirm your acceptance of our Privacy Policy:
        //                 </p>
        //             </div>

        //             <div className={style.checkedButtonContainer} id="checkedButtonContainer" style={{ margin: '5%' }} onClick={() => { handleOnChange() }} >
        //                 <img src={isChecked ? ic_checked : ic_unchecked} className={style.checkbox} id="checkbox" />
        //                 <p className={style.desc} style={{ marginLeft: '5%', color: '#429EFA', fontSize: '1.7vh', lineHeight: 1.5 }} >
        //                     ACCEPT PRIVACY TERMS AND END USER AGREEMENT - EULA
        //                     ALLOW ACCESS TO CAMERA, MICROPHONE AND USAGE DATA.
        //                 </p>
        //             </div>

        //             <div className={style.buttonContainer} >
        //                 <button className={style.button} id="modalbtn" onClick={() => { onPressButton() }}>LET'S GO!</button>
        //             </div>
        //         </div>
        //     </div>
        //     <PolicyInnerDetailsModal ref={policyInnerDetailsModalRef} />

        // </div>
    );
}

const useStyle = createUseStyles({
    ModalBody:{
        padding : "30px 40px 15px 40px",
    },
    mainContainer: {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 0,
        zIndex: 12000,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "top 0.5s ease-in-out",
        top: (props) => props.isModalShow ? 0 : '-100%',

    },
    modalContainer: {
        width: '60%',
        height: '85%',
        backgroundColor: 'white',
        alignSelf: 'center',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    modalContentsMainContainer: {
        width: '80%',
        height: '85%',
        backgroundColor: 'white',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    headerLabel: {
        width: '100%',
        color: '#429EFA',
        fontSize: '4vh',
        margin: 0
    },
    smallHeaderLabel: {
        width: '100%',
        color: '#2A2A2A',
        fontWeight: 'normal',
        fontSize: '2vh',
        margin: 0,
        marginTop: '1%'

    },
    desc: {
        width: '100%',
        maxWidth: '100%',
        // textAlign: 'center',
        color: 'black',
        margin: 0,
        fontWeight: 'normal',
        fontSize: '1.5vh',
        flexShrink: 1,


    },
    buttonContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        pointerEvents: (props) => props.isChecked ? 'auto' : 'none',

    },
    checkedButtonContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        padding:"15px 12px 16px 0px"
    },
    button: {
        margin: '2%',
        color: 'white',
        // backgroundColor: '#429EFA',
        backgroundColor: (props) => props.isChecked ? '#429EFA' : '#00000050',
        borderColor: 'transparent',
        padding: '1%',
        borderRadius: 100,
        paddingLeft: '8%',
        paddingRight: '8%',
        cursor: 'pointer'

    },
    contentsContainer: {
        // flex: 1,
        // display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        // alignSelf: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        // display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollBehavior: 'smooth',
        // whiteSpace: 'nowrap',
        margin: 0,
        marginTop: '1%',
        '&::-webkit-scrollbar': {
            width: '1vh'
        },

        /* Track */
        '&::-webkit-scrollbar-track': {
            'box-shadow': 'inset 0 0 1vh #00000020',
            'border-radius': '10px',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            'background': '#00000020',
            'border-radius': '10px'
        },


    },
    clickableOptionContainer: {
        width: '100%',
        height: '10%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '1%',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'

    },
    questionLabel: {
        width: '60%',
        fontSize: '1.8vh',
        fontWeight: 'normal',
        padding: 0,
        margin: 0,
        alignSelf: 'center',
        color: '#429EFA',


    },
    questionIcon: {
        width: '1.5vh',
        height: '1.5vh',
        objectFit: 'contain',
        objectPosition: 'center',
        alignSelf: 'center',
    },
    checkbox: {
        width: '3vh',
        height: '3vh',
        borderRadius: 100
    }
})

export default forwardRef(PolicyAgreementModal);
